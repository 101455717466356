<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ options.title }}</b-card-title>
      <b-card-text class="mr-25 mb-0">
        {{ options.subTitle }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in options.items"
          :key="index"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
        >

        <statistic-card-vertical
            :options="item.options"
        >
        </statistic-card-vertical>


        </b-col>
      </b-row>
    </b-card-body>
  </b-card> 
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import StatisticCardVertical from './StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardVertical
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: {
      },
    },

  },

  mounted()
  {
   this.$emit('elementLoaded', event)
  },


  data() {
   return {
      numberFrom: 0,
      numberTo: 10,
      duration: 1,
      scaleClass: false
    };
  },
}
</script>
